<template>
  <v-sheet color="grey lighten-5">
    <v-container fluid v-if="item">
      <v-row>
        <v-col cols="12" class="text-end">
          <v-btn
              v-if="userCan('create-candidate')"
              class="primary--text mr-3"
              large
              outlined
              :to="{
              name: 'create-candidate',
              params: {
                goTo: '/workforce/show/' + id
              }
            }"
          >
            <v-icon left>mdi-plus</v-icon>
            Add new candidate
          </v-btn
          >
          <v-btn
              color="primary"
              class="ml-3"
              large
              v-if="userCan('propose-candidate')"
              :dark="canProposeCandidate"
              :disabled="!canProposeCandidate"
              @click="updateProposeDialog(true)"
          >
            <v-icon left>mdi-account-multiple-plus</v-icon>
            Propose candidate
          </v-btn>
        </v-col>
        <v-col :cols="userCan('propose-candidate') ? '4' : '6'">
          <v-card
              class="mx-auto"
              flat
              outlined
              max-height="500px"
              v-if="jobPositionsList"
              color="grey lighten-2"
              style="overflow-y: scroll"
          >
            <v-subheader>
              <v-icon color="primary" left>mdi-account-group</v-icon>
              <div class="body-1 grey--text text--darken-3">
                Employees
              </div>
              <v-spacer/>
            </v-subheader>
            <v-card class="mx-auto" flat outlined>
              <v-list
                  dense
                  flat
                  two-line
                  v-for="(job, index) in jobPositionsList"
                  v-bind:key="job.id"
              >
                <v-subheader>
                  <div class="body-1 grey--text text--darken-3">
                    {{ job.name }}
                    <span class="caption grey--text">
                      {{ job.filled }} / {{ job.employees_number }}
                    </span>
                  </div>
                  <v-spacer/>
                </v-subheader>

                <v-list-item
                    selectable
                    inactive
                    class="inset-box-shadow"
                    v-for="(item, i) in job.employees"
                    :key="item.name + i"
                >
                  <v-list-item-icon>
                    <v-avatar size="35" color="primary"
                    >
                      <v-icon dark>mdi-account</v-icon>
                    </v-avatar
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle
                    >Contract started :
                      {{ formatDate(item["started_at"]) }}
                    </v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action v-if="userCan('terminate-contract')">
                    <v-btn
                        icon
                        color="warning"
                        @click="
                        jobPosition = job.id;
                        employeeId = item.id;
                        updateTerminationDialog(true);
                      "
                    >
                      <v-icon>mdi-clipboard-text-off</v-icon>
                    </v-btn
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="index !== jobPositionsList.length - 1"/>
              </v-list>
            </v-card>
          </v-card>
        </v-col>

        <v-col
            :cols="userCan('propose-candidate') ? '4' : '6'"
            class="sticky-position"
        >
          <v-card
              class="mx-auto"
              flat
              outlined
              ref="canPro"
              max-height="500px"
              v-if="jobPositionsList"
              color="grey lighten-2"
              style="overflow-y: scroll;"
          >
            <v-subheader>
              <v-icon color="primary" left>mdi-account-multiple-plus</v-icon>
              <div class="body-1 grey--text text--darken-3">
                Candidates proposed
              </div>
              <v-spacer/>
            </v-subheader>
            <v-card class="mx-auto" flat outlined>
              <v-list
                  dense
                  flat
                  two-line
                  v-for="(job, index) in jobPositionsList"
                  v-bind:key="job.id"
                  :id="job.id"
              >
                <v-subheader>
                  <div class="body-1 grey--text text--darken-3">
                    {{ job.name }}
                    <span class="caption grey--text">
                      ({{ job.candidates_proposed.length }})
                    </span>
                  </div>
                </v-subheader>
                <draggable
                    class="list-group dragArea"
                    group="people"
                    :sort="false"
                    v-bind:style="dropStyle"
                    :disabled="userCan('propose-candidate') ? false : true"
                    @change="jobPosition = job.id"
                    :list="job.candidates_proposed"
                >
                  <v-list-item
                      selectable
                      class="inset-box-shadow"
                      v-for="(item, i) in job.candidates_proposed"
                      :key="item.employee + i"
                  >
                    <v-list-item-icon>
                      <v-avatar size="30" color="primary"
                      >
                        <v-icon dark>mdi-account</v-icon>
                      </v-avatar
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.employee }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row>
                        <v-tooltip bottom v-if="item.admin_note">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                class="mx-1"
                                v-bind="attrs"
                                @click="triggerAdminNote($event, item)"
                                v-on="on"
                            >
                              <v-icon>mdi-note-text-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Notes</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.attachment_download">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                class="mx-1 primary--text"
                                v-if="item.attachment_download"
                                :link="true"
                                v-bind="attrs"
                                v-on="on"
                                target="_blank"
                                :href="item.attachment_download"
                            >
                              <v-icon color="primary">mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Download file</span>
                        </v-tooltip>
                        <v-tooltip bottom v-else>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                class="mx-1"
                                v-if="userCan('create-file-candidate')"
                                v-bind="attrs"
                                v-on="on"
                                @click="triggerAttachFile(item.id, job.id)"
                            >
                              <v-icon>mdi-file-upload</v-icon>
                            </v-btn>
                          </template>
                          <span>Attach file</span>
                        </v-tooltip>
                        <template v-if="item.response">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  small
                                  class="mx-1"
                                  v-if="userCan('confirm-candidate')"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                  triggerConfirmCandidate($event, item, job.id)
                                "
                              >
                                <v-icon color="primary">mdi-check</v-icon>
                              </v-btn>
                            </template>
                            <span>Add to employees</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  small
                                  class="mx-1"
                                  v-if="userCan('cancel-candidate')"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                  triggerCancelCandidate($event, item, job.id)
                                "
                              >
                                <v-icon color="warning">mdi-close</v-icon>
                              </v-btn>
                            </template>
                            <span>Cancel candidate propose</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  small
                                  class="mx-1"
                                  v-if="userCan('approve-candidate')"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="approveCandidate(item, job.id)"
                              >
                                <v-icon color="primary">mdi-thumb-up</v-icon>
                              </v-btn>
                            </template>
                            <span>Approve candidate</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                  icon
                                  small
                                  class="mx-1"
                                  v-if="userCan('reject-candidate')"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                  triggerRejectCandidate($event, item, job.id)
                                "
                              >
                                <v-icon color="warning">mdi-thumb-down</v-icon>
                              </v-btn>
                            </template>
                            <span>Reject candidate</span>
                          </v-tooltip>
                        </template>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                class="mx-1"
                                v-if="userCan('remove-candidate')"
                                v-bind="attrs"
                                v-on="on"
                                @click.native="removeCandidate(item, job.id)"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove from the list</span>
                        </v-tooltip>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index !== jobPositionsList.length - 1"/>
                </draggable>
              </v-list>
            </v-card>
          </v-card>
        </v-col>

        <v-col
            cols="4"
            class="sticky-position"
            v-if="userCan('propose-candidate')"
        >
          <v-card class="mx-auto" flat outlined max-height="500px" color="grey lighten-2" style="overflow-y: scroll">
            <v-subheader>
              <v-icon color="primary" left>mdi-account-multiple-plus</v-icon>
              <div class="body-1 grey--text text--darken-3">
                Candidates
                <span class="caption grey--text">
                  ({{ candidates.length }})
                </span>
              </div>
            </v-subheader>
            <v-card class="mx-auto" flat outlined>
              <v-list class="pt-0" dense flat min-height="200px">
                <v-subheader style="height: auto">
                  <v-text-field
                      v-model="search"
                      clearable
                      @click:clear="clearSearch"
                      solo
                      flat
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      placeholder="Search"
                  ></v-text-field>
                </v-subheader>
                <draggable
                    class="list-group"
                    :group="{ name: 'people', pull: 'clone', put: true }"
                    ghostClass="display-none"
                    v-if="candidates.length > 0"
                    :sort="false"
                    @start="drag = true"
                    @end="drag = false"
                    :disabled="!this.canProposeCandidate ? true : false"
                    :list="filteredCandidates"
                    :move="checkMove"
                >
                  <v-list-item
                      selectable
                      class="inset-box-shadow"
                      v-for="(item, i) in filteredCandidates"
                      :key="item.name + i"
                  >
                    <v-list-item-icon>
                      <v-avatar size="30" color="grey"
                      >
                        <v-icon dark>mdi-account</v-icon>
                      </v-avatar
                      >
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </draggable>
                <div class=" fill-height" v-else-if="candidates.length == 0">
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <div class="body-1 grey--text text--darken-3">
                      Please add more candidates
                    </div>
                  </v-row>
                </div>
                <div class=" fill-height" v-else>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular indeterminate color="primary"/>
                  </v-row>
                </div>
              </v-list>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-menu
          ref="menuAdminNote"
          v-model="menuAdminNote"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          absolute
          :position-x="x"
          :position-y="y"
          offset-y
          min-width="200px"
      >
        <v-card
        >
          <v-toolbar dense dark color="primary" elevation="0">
            <v-toolbar-title>Note</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            {{ currentNote }}
          </v-card-text>
        </v-card>
      </v-menu>
      <v-menu
          ref="menuDate"
          v-model="menuDate"
          :close-on-content-click="false"
          :return-value.sync="date"
          :nudge-right="40"
          transition="scale-transition"
          absolute
          :position-x="x"
          :position-y="y"
          offset-y
          min-width="auto"
      >
        <v-date-picker v-model="date" no-title scrollable color="primary">
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menuDate = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="confirmCandidate">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
          ref="menuNote"
          v-model="menuNote"
          :close-on-content-click="false"
          :nudge-right="20"
          transition="scale-transition"
          absolute
          :position-x="x"
          :position-y="y"
          offset-y
          min-width="350px"
      >
        <v-card
        >
          <v-card-text>
            <v-textarea
                filled
                v-model="responseNote"
                name="responseNote"
                label="Please enter rejection reason/s"
            ></v-textarea>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuNote = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="rejectCandidate">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-menu
          ref="menuCancel"
          v-model="menuCancel"
          :close-on-content-click="false"
          :nudge-right="20"
          transition="scale-transition"
          absolute
          :position-x="x"
          :position-y="y"
          offset-y
          min-width="350px"
      >
        <v-card
        >
          <v-card-text>
            <v-radio-group v-model="cancelType">
              <v-radio
                  v-for="type in cancelTypes"
                  :key="type.id"
                  :label="type.name"
                  :value="type.id"
              ></v-radio>
            </v-radio-group>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menuCancel = false">
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                :disabled="!cancelType"
                @click="cancelCandidate"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <file-dialog
          v-if="fileDialog"
          :dialog.sync="fileDialog"
          :go-to-url.sync="goTo"
          @dialog-cancel="updateFileDialog(false)"
          @dialog-update="updateFileDialog"
      ></file-dialog>
      <propose-candidate-dialog
          v-if="showProposeDialog"
          :job-position.sync="jobPosition"
          :workforce-id.sync="id"
          :candidate.sync="candidateId"
          :dialog.sync="showProposeDialog"
          @dialog-cancel="updateProposeDialog(false)"
          @dialog-update="confirmProposeUpdate"
      ></propose-candidate-dialog>
      <terminate-contract-dialog
          v-if="jobPosition"
          :job-position.sync="jobPosition"
          :employee-id.sync="employeeId"
          :workforce-id.sync="id"
          :dialog.sync="showTerminationDialog"
          @dialog-cancel="updateTerminationDialog(false)"
          @dialog-update="confirmTermination"
      ></terminate-contract-dialog>
    </v-container>
  </v-sheet>
</template>
<script>
import {mapGetters} from "vuex";
import permission from "@/mixins/permission";
import date from "@/mixins/date";
import draggable from "vuedraggable";

export default {
  Name: "assignTab",
  components: {
    draggable,
    ProposeCandidateDialog: () =>
        import("@/components/core/dialogs/ProposeCandidate"),
    TerminateContractDialog: () =>
        import("@/components/core/dialogs/TerminateContract"),
    FileDialog: () => import("@/components/core/dialogs/AttachFileDialog")
  },
  mixins: [permission, date],
  data() {
    return {
      tab: null,
      jobPosition: null,
      date: new Date().toISOString().substr(0, 10),
      responseNote: "",
      currentNote: "",
      menuDate: false,
      menuNote: false,
      menuAdminNote: false,
      menuCancel: false,
      x: 0,
      y: 0,
      employeeId: null,
      candidateConfirm: null,
      candidateCancel: null,
      candidateReject: null,
      candidateRemove: null,
      cancelType: null,
      fileTypeId: null,
      candidateId: null,
      current: null,
      drag: false,
      showProposeDialog: false,
      fileDialog: false,
      currentId: null,
      currentJP: null,
      type: "employees",
      goTo: "",
      showTerminationDialog: false,
      search: ""
    };
  },

  computed: {
    ...mapGetters({
      candidates: "candidates/items",
      cancelTypes: "candidates/cancelTypes",
      item: "workforce/item"
    }),
    jobPositionsList() {
      return this.item.job_positions || [];
    },
    icon() {
      return this.$route.meta.icon || "";
    },
    id() {
      return this.$route.params.id || null;
    },
    store() {
      return this.$route.meta.store || "";
    },
    title() {
      return this.$route.meta.title || "";
    },
    namespace() {
      return this.$route.meta.namespace || "";
    },
    canProposeCandidate() {
      return (
          this.item.fill_rate.positions_to_fill >= this.item.fill_rate.totalFilled
      );
    },
    filteredCandidates() {
      return this.candidates.filter(candidate => {
        return candidate.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    dropStyle() {
      if (this.drag) {
        const divHeight = 150 + this.item.proposed_candidates_count * 50;
        return {
          height: divHeight + "px",
          background: "#f4f4f4",
          border: "dashed #ccc 3px"
        };
      }
      return {};
    }
  },
  async created() {
    await this.$store.dispatch("candidates/getItems", {all: true});
    this.$store.dispatch("candidates/cancelTypes");
    let _this = this;
    Event.$on("file_upload_complete", function (url) {
      if (_this.currentJP) {
        const attachFile = {
          id: _this.currentId,
          workforce_request_id: _this.id,
          job_position_id: _this.currentJP,
          attachment: url
        };
        _this.$store.dispatch("candidates/attachFile", attachFile).then(() => {
          _this.fileDialog = false;
          _this.getData();
        });
      }
    });
  },
  methods: {
    async getData() {
      const _this = this;
      await _this.$store.dispatch(_this.store + "/showItem", _this.id);
    },
    updateProposeDialog(dialog) {
      this.showProposeDialog = dialog;
    },
    updateFileDialog(dialog) {
      this.fileDialog = dialog;
    },
    cancelProposeDialog(dialog) {
      this.candidateId = null;
      this.jobPosition = null;
      this.showProposeDialog = dialog;
    },
    confirmProposeUpdate(dialog) {
      this.getData();
      this.candidateId = null;
      this.jobPosition = null;
      this.showProposeDialog = dialog;
    },
    updateTerminationDialog(dialog) {
      this.showTerminationDialog = dialog;
    },
    confirmTermination(dialog) {
      this.showTerminationDialog = dialog;
      this.getData();
    },
    async triggerAttachFile(id, job) {
      this.currentId = id;
      this.currentJP = job;
      this.goTo = "/workforce/show/" + id;
      this.fileDialog = true;
    },
    triggerAdminNote(e, item) {
      e.preventDefault();
      this.x = e.clientX;
      this.y = e.clientY;
      this.currentNote = item.admin_note;
      this.$nextTick(() => {
        this.menuAdminNote = true;
      });
    },
    triggerConfirmCandidate(e, item, job) {
      e.preventDefault();
      this.candidateConfirm = {
        id: item.id,
        workforce_request_id: this.id,
        job_position_id: job
      };
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menuDate = true;
      });
    },
    triggerCancelCandidate(e, item, job) {
      e.preventDefault();
      this.candidateCancel = {
        id: item.id,
        workforce_request_id: this.id,
        job_position_id: job
      };
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menuCancel = true;
      });
    },
    triggerRejectCandidate(e, item, job) {
      e.preventDefault();
      this.candidateReject = {
        id: item.id,
        workforce_request_id: this.id,
        job_position_id: job
      };
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.menuNote = true;
      });
    },
    confirmCandidate() {
      this.candidateConfirm.employee_starting_at = this.date;
      this.$store.dispatch("candidates/confirm", this.candidateConfirm);
      this.menuDate = false;
      setTimeout(
          this.$store.dispatch("candidates/getItems", {all: true}),
          500
      );
      setTimeout(this.getData(), 500);
    },
    cancelCandidate() {
      this.candidateCancel.cancel_response_id = this.cancelType;
      this.$store.dispatch("candidates/cancel", this.candidateCancel);
      this.cancelType = null;
      this.menuCancel = false;
      setTimeout(this.getData(), 500);
    },
    approveCandidate(item, job) {
      const candidateApprove = {
        id: item.id,
        workforce_request_id: this.id,
        job_position_id: job
      };
      this.$store.dispatch("candidates/approve", candidateApprove);
      setTimeout(this.getData(), 500);
    },
    rejectCandidate() {
      this.candidateReject.response_note = this.responseNote;
      this.$store.dispatch("candidates/reject", this.candidateReject);
      this.responseNote = "";
      this.menuNote = false;
      setTimeout(this.getData(), 500);
    },
    async removeCandidate(item, job) {
      this.candidateRemove = {
        id: item.id,
        workforce_request_id: this.id,
        job_position_id: job
      };
      await this.$store.dispatch("candidates/remove", this.candidateRemove);
      await this.getData();
    },
    //this was used for allow/disallow some users to do some actions
    canDoQuickActions(item) {
      if (this.hasRoleGroup("hr-supplier")) {
        if (item.allow_actions) {
          return true;
        }
        return false;
      }
      return true;
    },
    checkMove(evt) {
      //let height = this.$refs.canPro.clientHeight;
      //    console.log(height);
      this.candidateId = evt.draggedContext.element.id;
      this.jobPosition = evt.to.parentElement.id;
      if (this.jobPosition) {
        this.drag = false;
        this.updateProposeDialog(true);
      }
    },
    clearSearch() {
      this.search = '';
    }

  }

};
</script>
<style>
.sortable-chosen.sortable-ghost {
  display: none;
}

.inset-box-shadow {
  box-shadow: 1px 1px 1px -1px grey inset;
  cursor: grab;
}

sticky-position {
  position: sticky !important;
  align-self: flex-start;
}
</style>
